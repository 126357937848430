:root {
  --primary: #f9b115;
  --primary-hover: #bb8510;
}

/* .btnColor {
    background-color: var(--primary);
}

.btnColor:hover {
    background-color: var(--primary-hover);
} */

/* .sidebarProperties {
    background-color: var(--primary) !important;
} */

.loginCard {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

@media screen and (max-width: 600px) {
  .card {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    padding: 0px 20px 10px 20px;
  }
}

@media screen and (max-width: 600px) {
  .loginCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 30px 20px 10px 20px;
    box-shadow: none;
  }
}

@media screen and (max-width: 600px) {
  .logocard {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    text-align: center;
    width: 85% !important;
  }
}

.carousel_item {
  height: 300px !important;
}

.color {
  background-color: var(--primary-hover) !important;
}

.EventComponent {
  background-color: #faedcd !important;
}

.ConnectComponent {
  background-color: #ffe5d9 !important;
}

/* App footer */
.CFooter {
  margin: 0 !important;
  padding: 0 !important;
}

.footer {
  width: 100%;
  background-color: var(--dashboard);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border: none;
}

/* Events Footer */
.footerEvents>* {
  flex: 1 100%;
}

.footer__addr {
  margin-right: 1.25em;
  margin-bottom: 2em;
}

.footer__logo {
  font-family: 'Pacifico', cursive;
  font-weight: 400;
  text-transform: lowercase;
  font-size: 1.5rem;
}

.footer__addr h2 {
  margin-top: 1.3em;
  font-size: 15px;
  font-weight: 400;
}

.nav__title {
  font-weight: 400;
  font-size: 15px;
}

.footer address {
  font-style: normal;
  color: #999;
}

.buttonFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  max-width: max-content;
  background-color: rgb(33, 33, 33, 0.07);
  border-radius: 100px;
  color: #2f2f2f;
  line-height: 0;
  margin: 0.6em 0;
  font-size: 1rem;
  padding: 0 1.3em;
}

.footer ul {
  list-style: none;
  padding-left: 0;
}

.footer li {
  line-height: 2em;
}

.footer a {
  text-decoration: none;
}

.footer__nav {
  display: flex;

}

.footer__nav>* {
  flex: 1 50%;
  margin-right: 1.25em;
}

.nav__ul a {
  color: #999;
}

.nav__ul--extra {
  column-count: 1;
  column-gap: 1.25em;
}

.legal {
  display: flex;
  flex-wrap: wrap;
  color: #999;
}

.legal__links {
  display: flex;
  align-items: center;
}

.heart {
  color: #2f2f2f;
}

@media screen and (min-width: 24.375em) {
  .legal .legal__links {
    margin-left: auto;
  }
}

@media screen and (min-width: 40.375em) {
  .footer__nav>* {
    flex: 1;
  }

  .nav__item--extra {
    flex-grow: 2;
  }

  .footer__addr {
    flex: 1 0px;
  }

  .footer__nav {
    flex: 2 0px;
  }
}

.drag-drop {
  background: #fff;
  border: 1px solid var(--border-color);
  border-radius: 8px;
}

.document-uploader {
  border: 2px dashed #4282fe;
  background-color: #f4fbff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  cursor: pointer;

  &.active {
    border-color: #6dc24b;
  }

  .upload-info {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    height: auto;

    svg {
      font-size: 36px;
      margin-right: 1rem;
    }

    div {
      p {
        margin: 0;
        font-size: 16px;
      }

      p:first-child {
        font-weight: bold;
      }
    }
  }

  .file-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    height: 30vh;
    height: auto;
    overflow: hidden;

    &__container {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }

  .file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border: 1px solid var(--border-color);
    border-radius: 8px;

    .file-info {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      flex: 1;

      p {
        margin: 0;
        font-size: 14px;
        color: #333;
      }
    }

    .file-actions {
      cursor: pointer;

      svg {
        font-size: 18px;
        color: #888;
      }

      &:hover {
        svg {
          color: #d44;
        }
      }
    }
  }

  .browse-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    cursor: pointer;
    background-color: var(--primary-color);

    &:hover {
      background-color: transparent;
    }
  }

  .success-file {
    display: flex;
    align-items: center;
    color: #6dc24b;

    p {
      margin: 0;
      font-size: 14px;
      font-weight: bold;
    }
  }

  input[type="file"] {
    display: none;
  }
}

.nav {
  --cui-nav-link-color: black;
  --cui-nav-link-hover-color: black
}

.nav-tabs {
  --cui-nav-tabs-link-active-color: black;
  --cui-nav-tabs-link-active-bg: #F5F5F5;
}

@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }
}

.pnf_code {
  padding-top: 80px;
  font-size: 200px;
}

.pnf_message {
  font-size: 50px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.pnf_message_desc {
  padding-top: 30px;
  font-size: 16px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.regSuccessMessage {
  font-size: 20px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.regSuccessDesc {
  font-size: 30px;
  padding-top: 50px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
