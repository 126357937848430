// Here you can add other styles
:root {
    --primary: #f9b115;
    --primary-hover: #d49713;
    --dashboard: #ce9211;
}

.sidebarProperties {
    background-color: var(--dashboard);
}

.sidebarProperties li {
    color: white !important;
    font-size: medium !important;
}

.sidebarProperties a {
    color: white !important;
    font-size: medium;
}

.btnColor {
    background-color: var(--primary);
}

.btnColor:hover {
    background-color: var(--primary-hover);
}

.btnColor:active {
    background-color: var(--primary-hover) !important;
}

.CFooter {
    margin: 0 !important;
    padding: 0 !important;
}

.footer {
    width: 100%;
    background-color: var(--dashboard);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border: none;
}

.page-link.active, .active > .page-link {
    background-color: var(--primary);
    border-color: black;
    color: black;
}

.page-link {
    color: black
}

.text-primary {
    margin: 2em !important;
    --cui-text-opacity: 1;
    color: #d5a439 !important;
}

.fc-button {
    background-color: #ce9211 !important;
    border-color: transparent !important;
    margin: 0.5em !important;
    border-radius: 0.5em !important;
}


.fc-button::after {
    background-color: #ce9211 !important;
    border-color: transparent !important;
}

.table-action-button {

    --cui-btn-line-height: 14.5px;
    
}