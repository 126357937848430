.fc {
    --fc-small-font-size: .85em;
    --fc-page-bg-color: #fff;
    --fc-neutral-bg-color: rgba(208, 208, 208, .3);
    --fc-neutral-text-color: #808080;
    --fc-border-color: var(--cui-border-color, #{$border-color});
    --fc-button-text-color: #fff;
    --fc-button-bg-color: #2c3e50;
    --fc-button-border-color: #2c3e50;
    --fc-button-hover-bg-color: #1e2b37;
    --fc-button-hover-border-color: #1a252f;
    --fc-button-active-bg-color: #1a252f;
    --fc-button-active-border-color: #151e27;
    --fc-event-bg-color: #3788d8;
    --fc-event-border-color: #3788d8;
    --fc-event-text-color: #fff;
    --fc-event-selected-overlay-color: rgba(0, 0, 0, .25);
    --fc-event-resizer-thickness: 8px;
    --fc-event-resizer-dot-total-width: 8px;
    --fc-event-resizer-dot-border-width: 1px;
    --fc-non-business-color: rgba(215, 215, 215, .3);
    --fc-bg-event-color: rgb(143, 223, 130);
    --fc-bg-event-opacity: .3;
    --fc-highlight-color: rgba(188, 232, 241, .3);
    --fc-today-bg-color: rgba(255, 220, 40, .15);
    --fc-now-indicator-color: var(--cui-danger);
  }
  